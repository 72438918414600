import React, { FC, Fragment } from 'react'
import NavLink from './ui/NavLink'
import Link from 'next/link'
import Logo from '../svg/logo.svg'
import { applicationFormOpen, partnershipFormOpen } from '@src/store'
import c from 'clsx'
import { Popover, Transition } from '@headlessui/react'
import { useRouter } from 'next/router'

const Nav: FC = () => {
  const router = useRouter()

  return (
    <Popover as={Fragment}>
      {({ open }) => (
        <>
          <Transition
            enter='transition duration-300 ease-out'
            enterFrom='transform opacity-0'
            enterTo='transform opacity-100'
            leave='transition duration-200 ease-out'
            leaveFrom='transform opacity-100'
            leaveTo='transform opacity-0'
            as={Fragment}
          >
            <Popover.Overlay className='lg:hidden bg-grayscale-0 bg-opacity-30 fixed inset-0' />
          </Transition>
          <div className='relative z-30'>
            <div className='bg-frost z-10'>
              <div className='container container-xl px-6 xl:px-0 flex lg:justify-start justify-between items-center h-14 lg:h-19'>
                <Link href='/'>
                  <Logo className='lg:w-18 h-6 lg:h-auto' />
                </Link>

                <Popover.Panel
                  static
                  as='nav'
                  className={c(
                    'lg:contents origin-top absolute lg:static pointer-events-none lg:pointer-events-auto top-0 left-0 w-full lg:w-auto bg-white-0 lg:bg-opacity-0 px-6 lg:px-0 pb-6 lg:pb-0 transition transform opacity-0 lg:opacity-100 lg:translate-y-0 -translate-y-1/4 duration-200 pt-14 lg:pt-0 -z-1',
                    open && 'translate-y-0 opacity-100 pointer-events-auto'
                  )}
                >
                  <ul className='lg:flex lg:space-x-7 text-p250 mb-7 lg:mb-0 lg:ml-10 lg:mr-auto'>
                    <Popover.Button as='li' className='mb-4 lg:mb-0'>
                      <NavLink
                        href='/about'
                        activeClassName='lg:border-red-100 lg:text-red-100 font-semibold'
                      >
                        <span className='block box-content py-0 lg:hover:border-red-100 lg:py-5 lg:border-b-2 border-transparent'>
                          О нас
                        </span>
                      </NavLink>
                    </Popover.Button>
                    <Popover.Button as='li' className='mb-4 lg:mb-0'>
                      <NavLink
                        href='/faq'
                        activeClassName='lg:border-red-100 lg:text-red-100 font-semibold'
                      >
                        <span className='block box-content py-0 lg:hover:border-red-100 lg:py-5 lg:border-b-2 border-transparent'>
                          Частые вопросы
                        </span>
                      </NavLink>
                    </Popover.Button>
                    <Popover.Button as='li' className='mb-4 lg:mb-0'>
                      <NavLink
                        href='/partners'
                        activeClassName='lg:border-red-100 lg:text-red-100 font-semibold'
                      >
                        <span className='block box-content py-0 lg:hover:border-red-100 lg:py-5 lg:border-b-2 border-transparent'>
                          Стать партнёром
                        </span>
                      </NavLink>
                    </Popover.Button>
                    <Popover.Button as='li' className='mb-4 lg:mb-0'>
                      <NavLink
                        href='/investors'
                        activeClassName='lg:border-red-100 lg:text-red-100 font-semibold'
                      >
                        <span className='block box-content py-0 lg:hover:border-red-100 lg:py-5 lg:border-b-2 border-transparent'>
                          Инвесторам
                        </span>
                      </NavLink>
                    </Popover.Button>
                    {/* <li className='mb-4 lg:mb-0'><Popover.Button as='a' href='#'>Стать партнером</Popover.Button></li> */}
                    {/* <li className='mb-4 lg:mb-0'><Popover.Button as='a' href='#'>Поставщики</Popover.Button></li> */}
                  </ul>
                  {router.pathname === '/partners' ? (
                    <div
                      className='contents'
                      onClick={() => partnershipFormOpen.set(true)}
                    >
                      <Popover.Button
                        role='button'
                        className='px-5 h-9 bg-red-100 hover:bg-red-50 active:bg-red-150 rounded-full text-white-0 leading-full text-p300 font-semibold w-full lg:w-auto'
                      >
                        Стать партнёром
                      </Popover.Button>
                    </div>
                  ) : (
                    router.pathname !== '/investors' && (
                      <div
                        className='contents'
                        onClick={() => applicationFormOpen.set(true)}
                      >
                        <Popover.Button
                          role='button'
                          className='px-5 h-9 bg-red-100 hover:bg-red-50 active:bg-red-150 rounded-full text-white-0 leading-full text-p300 font-semibold w-full lg:w-auto'
                        >
                          Подать заявку
                        </Popover.Button>
                      </div>
                    )
                  )}
                </Popover.Panel>

                <Popover.Button
                  className='lg:hidden p-2 left-2 box-content relative'
                  role='button'
                >
                  {open ? (
                    <svg
                      width='20'
                      height='20'
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect
                        x='2.22266'
                        y='16.364'
                        width='20'
                        height='2'
                        rx='1'
                        transform='rotate(-45 2.22266 16.364)'
                        fill='black'
                      />
                      <rect
                        x='3.63672'
                        y='2.22182'
                        width='20'
                        height='2'
                        rx='1'
                        transform='rotate(45 3.63672 2.22182)'
                        fill='black'
                      />
                    </svg>
                  ) : (
                    <svg
                      width='20'
                      height='20'
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect y='5' width='20' height='2' rx='1' fill='black' />
                      <rect
                        x='7'
                        y='13'
                        width='13'
                        height='2'
                        rx='1'
                        fill='black'
                      />
                    </svg>
                  )}
                </Popover.Button>
              </div>
            </div>
          </div>
        </>
      )}
    </Popover>
  )
}

export default Nav
